import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuBar from './MenuBar'; // Import the shared MenuBar component
import './css/SubtopicsPage.css'; // Add a CSS file for styling
import config from '../constants'; // Import the config object
import TopBar from './TopBar';

const SubtopicsPage = () => {
  const navigate = useNavigate(); // Hook for programmatic navigation
  const [subtopics, setSubtopics] = useState([]);

  // Fixed and encoded subject name
  const subjectName = 'PYQs Year Wise';
  const encodedSubjectName = encodeURIComponent(subjectName);

  useEffect(() => {
    const fetchSubtopicsAndWallet = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }

      try {
        // Fetch subtopics with the number of questions
        const subtopicsResponse = await fetch(`${config.baseUrl}/subtopics/${encodedSubjectName}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const subtopicsData = await subtopicsResponse.json();
        if (subtopicsResponse.ok) {
          setSubtopics(subtopicsData.subtopics);
        } else {
          console.error('Error fetching subtopics:', subtopicsData.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSubtopicsAndWallet();
  }, [encodedSubjectName]); // Added as a dependency (though it doesn't change)

  const handleSubtopicSelect = async (subtopicId, subtopicName) => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }

    try {
      // Deduct 1 from wallet balance
      const response = await fetch(`${config.baseUrl}/wallet/deduct`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ amount: 20 }),
      });
      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('selectedSubtopicId', subtopicId);
        localStorage.setItem('selectedSubjectName', encodedSubjectName);
        
        // Navigate to the quiz page after successful deduction
        navigate(`/GrandTest/${encodedSubjectName}/${subtopicName}`);
      } else {
        console.error('Error deducting wallet balance:', data.message);
        alert(data.message || 'Failed to deduct wallet balance.');
      }
    } catch (error) {
      console.error('Error deducting wallet balance:', error);
      alert('An error occurred while processing your request.');
    }
  };

  return (
    <div className="subtopics-page">
      <TopBar /> {/* TopBar at the top */}
      <header className="subtopics-header">
        <h1>Grand Tests</h1> {/* Fixed subject name displayed */}
        <h6>-Time For each question: 1 Min</h6>
        <h6>-Recommended to Complete Grand Test In One Go</h6>
        <h6>-Pay As You Solve : Per GT ₹20</h6>
        <h5><b>-Buy Pro Plan to solve Unlimited</b></h5>
      </header>
      <div className="subtopics-container">
        {subtopics.length ? (
          subtopics.map((subtopic) => (
            <div 
              key={subtopic.id} 
              className="subtopic-card" 
              onClick={() => handleSubtopicSelect(subtopic.id, subtopic.name)}
            >
              <h3>{subtopic.name}</h3>
              <p>{subtopic.question_count} <strong>MCQs</strong> </p> {/* Display question count */}
            </div>
          ))
        ) : (
          <p className="loading-text">Loading GTs...</p>
        )}
      </div>
      <MenuBar /> {/* Use the shared MenuBar */}
    </div>
  );
};

export default SubtopicsPage;
