import React from 'react';
import MenuBar from './MenuBar'; // Import the shared MenuBar component
import TopBar from './TopBar'; // Import TopBar component
import './css/MakeiOSApp.css'; // Import a CSS file for styling
import image1 from './step1.jpg'; // Import the first step image
import image2 from './step2.jpg'; // Import the second step image

const MakeiOSApp = () => {
  return (
    <div className="make-ios-app">
      <TopBar /> {/* TopBar at the top */}
      <h1>Steps to Create an iOS App</h1>
      <div className="step">
        <h2>Step 1</h2>
        <img src={image1} alt="Step 1" className="step-image" />
        <p>Follow the first step to set up your iOS app.</p>
      </div>
      <div className="step">
        <h2>Step 2</h2>
        <img src={image2} alt="Step 2" className="step-image" />
        <p>Follow the second step to continue the setup process.</p>
      <MenuBar />
      </div>
    </div>
  );
};

export default MakeiOSApp;
