import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuBar from './MenuBar'; // Import the shared MenuBar component
import './css/DashboardPage.css'; // Import the CSS file
import config from '../constants'; // Import config containing baseUrl
import banner from './banner.jpeg'; // Import the banner image
import TopBar from './TopBar'; // Import TopBar component
import androidIcon from './icons8-google-play-store-240.png'; // Import Android Play Store icon
import iosIcon from './icons8-apple-logo-500.png'; // Import iOS App Store icon

const DashboardPage = () => {
  const [dailyMCQ, setDailyMCQ] = useState(null); // State to hold the daily MCQ data
  const [selectedOptionId, setSelectedOptionId] = useState(null); // State to track selected option
  const [isAnswered, setIsAnswered] = useState(false); // State to track if the question is answered
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      handleLogout(); // Logout if no token
      return;
    }

    // Fetch daily MCQ question
    axios
      .get(`${config.baseUrl}/daily-mcq`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDailyMCQ(response.data);
      })
      .catch((error) => {
        console.error('Error fetching daily MCQ:', error.response || error);
      });
  }, [handleLogout]);

  const handleMCQAnswer = (selectedId) => {
    setSelectedOptionId(selectedId); // Set selected option
    setIsAnswered(true); // Mark as answered
  };

  return (
    <div className="dashboard-page">
      <TopBar /> {/* TopBar at the top */}

      {/* Banner Section */}
      <div className="banner">
        <img src={banner} alt="Website Banner" className="banner-image" />
      </div>

      <div className="dashboard-content">
        {/* Daily MCQ Card */}
        {dailyMCQ && (
          <div className="daily-mcq-card">
            <h3>Daily MCQ</h3>
            <div className="question">
              <p>{dailyMCQ.question}</p>
              {dailyMCQ.imageUrl && (
                <img src={dailyMCQ.imageUrl} alt="MCQ Question" className="mcq-image" />
              )}
            </div>

            <div className="options">
              {dailyMCQ.options.map((option) => {
                const isOptionSelected = selectedOptionId === option.id;
                const isCorrectAnswer = option.id === dailyMCQ.correctOptionId;
                const isIncorrectAnswer = isOptionSelected && !isCorrectAnswer;
                const buttonClass = isOptionSelected
                  ? isCorrectAnswer
                    ? 'correct'
                    : isIncorrectAnswer
                    ? 'incorrect'
                    : ''
                  : '';

                return (
                  <button
                    key={option.id}
                    className={`option-button ${buttonClass}`}
                    onClick={() => handleMCQAnswer(option.id)}
                    disabled={isAnswered} // Disable buttons after answer
                  >
                    {option.text}
                  </button>
                );
              })}
            </div>

            {isAnswered && dailyMCQ.explanation && (
              <div className="answer-explanation">
                <p><strong>Explanation:</strong> {dailyMCQ.explanation}</p>
              </div>
            )}
          </div>
        )}

        {/* Download Section */}
        <div className="download-section">
          {/* Download Android APK */}
          <a
            href="https://subspace.in.net/download/QBankApp.apk"
            target="_blank"
            rel="noopener noreferrer"
            className="download-card"
          >
            <img
              src={androidIcon}
              alt="Download APK"
              className="download-icon"
            />
            <div className="download-text">Download APK</div>
          </a>

          {/* Download iOS App */}
          <div
            className="download-card"
            onClick={() => navigate('/makeiOSapp')}
          >
            <img
              src={iosIcon}
              alt="Download iOS App"
              className="download-icon"
            />
            <div className="download-text">Go to Make iOS App</div>
          </div>

        </div>

        <MenuBar />
      </div>
    </div>
  );
};

export default DashboardPage;
