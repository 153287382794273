import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import config from '../constants';
import './css/QuizPage.css';

const QuizPage = () => {
  const { baseUrl } = config;
  const { subtopicName } = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [dotStatus, setDotStatus] = useState([]);
  const [quizComplete, setQuizComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timerWidth, setTimerWidth] = useState(100);
  const [showExitModal, setShowExitModal] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);  // Image overlay state
  const [overlayImage, setOverlayImage] = useState("");  // Image URL for overlay

  const timerLimit = 60 * 1000; // 60 seconds in milliseconds
  const timerInterval = 100; // Update the timer every 100ms

  const timerRef = useRef(null);

  const handleSubmitQuiz = useCallback(async (displayedQuestions = questions.slice(0, currentQuestionIndex + 1)) => {
    console.log('Submitting Quiz:', { selectedOptions, displayedQuestions });

    const token = localStorage.getItem('token');
    const subtopicId = localStorage.getItem('selectedSubtopicId');
    if (!token || !subtopicId) {
      navigate('/login');
      return;
    }

    const fullAnswers = displayedQuestions.map((question) => {
      const selectedOptionId = selectedOptions[question.question_id];
      return {
        question_id: question.question_id,
        option_id: selectedOptionId || null,
      };
    });

    console.log('Full Answers:', fullAnswers);

    try {
      const response = await fetch(`${baseUrl}/submit-quiz`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ subtopicId, answers: fullAnswers }),
      });

      const data = await response.json();
      if (response.ok) {
        navigate('/quiz-result', { state: { resultId: data.resultId } });
      } else {
        setError(data.message || 'Error submitting the quiz.');
      }
    } catch (err) {
      setError('Failed to submit the quiz. Please try again later.');
    }
  }, [selectedOptions, questions, currentQuestionIndex, baseUrl, navigate]);

  const initializeDotStatus = useCallback((numQuestions) => {
    const initialStatus = Array(numQuestions).fill('unattempted');
    setDotStatus(initialStatus);
  }, []);

  const handleNextQuestion = useCallback(() => {
    clearInterval(timerRef.current);
    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setIsAnswerCorrect(null);
    } else {
      setQuizComplete(true);
      handleSubmitQuiz();
    }
  }, [currentQuestionIndex, questions.length, handleSubmitQuiz]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/grandtestquestions/${subtopicName}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (response.ok) {
          setQuestions(data.questions);
          initializeDotStatus(data.questions.length);
        } else {
          setError(data.message || 'Error fetching questions.');
        }
      } catch (err) {
        setError('Failed to fetch questions. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [subtopicName, baseUrl, navigate, initializeDotStatus]);

  const startTimer = useCallback(() => {
    setTimerWidth(100);
    const decrement = 100 / (timerLimit / timerInterval);
    timerRef.current = setInterval(() => {
      setTimerWidth((prev) => {
        if (prev <= 0) {
          clearInterval(timerRef.current);
          handleNextQuestion();
          return 0;
        }
        return prev - decrement;
      });
    }, timerInterval);
  }, [timerLimit, timerInterval, handleNextQuestion]);

  useEffect(() => {
    if (questions.length > 0) {
      startTimer();
    }
    return () => clearInterval(timerRef.current);
  }, [currentQuestionIndex, questions, startTimer]);

  const handleOptionSelect = async (questionId, selectedOptionId) => {
    if (selectedOptions[questionId] !== undefined) return;

    try {
      const response = await fetch(`${baseUrl}/options/${selectedOptionId}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();

      if (response.ok) {
        const isCorrect = data.is_correct === 1;
        
        // Update selectedOptions state
        setSelectedOptions((prev) => ({ ...prev, [questionId]: selectedOptionId }));
        setIsAnswerCorrect(isCorrect);

        updateDotStatus(currentQuestionIndex, isCorrect);

        // Wait briefly to ensure state updates are applied
        setTimeout(() => handleNextQuestion(), 1000);
      } else {
        setError(data.message || 'Error validating the answer.');
      }
    } catch (err) {
      setError('Error validating the answer. Please try again.');
    }
  };

  const updateDotStatus = (index, isCorrect) => {
    setDotStatus((prev) => {
      const updated = [...prev];
      updated[index] = isCorrect ? 'correct' : 'incorrect';
      return updated;
    });
  };

  const handleExitQuiz = () => {
    setShowExitModal(true);
  };

  const confirmExitQuiz = async () => {
    setShowExitModal(false);
    await handleSubmitQuiz();
  };

  const cancelExitQuiz = () => {
    setShowExitModal(false);
  };

  const openOverlay = (imageUrl) => {
    setOverlayImage(imageUrl);
    setIsOverlayOpen(true);
  };

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  if (loading) {
    return (
      <div className="loading-screen">
        Loading Questions
        <div className="rotating-circle"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error-screen">Error: {error}</div>;
  }

  if (quizComplete) {
    return (
      <div className="quiz-complete">
        Submitting Your Answers
        <div className="rotating-circle"></div>
      </div>
    );
  }

  return (
    <div className="quiz-page">
      <button className="close-icon" onClick={handleExitQuiz}>
        X
      </button>

      {/* Timer Bar */}
      <div className="quiz-timer" style={{ width: `${timerWidth}%` }}></div>

      {/* Logo */}
      <div className="quiz-banner">
        <img src={logo} alt="Quiz Logo" className="quiz-logo" />
      </div>

      {/* Dots */}
      <div className="dot-container">
        {dotStatus.map((status, index) => (
          <span key={index} className={`dot ${status}`}></span>
        ))}
      </div>

      {/* Question Text */}
      <div className="quiz-question">
        {questions[currentQuestionIndex].question_text}
        {questions[currentQuestionIndex].q_image && (
          <img
            src={questions[currentQuestionIndex].q_image}
            alt="Question"
            className="question-image"
            onClick={() => openOverlay(questions[currentQuestionIndex].q_image)} // Open overlay
          />
        )}
      </div>

      {/* Options */}
      <div className="quiz-options">
        {questions[currentQuestionIndex].options.map((option, index) => {
          const label = ['A', 'B', 'C', 'D'][index]; // Labels for options
          const selectedOptionId = selectedOptions[questions[currentQuestionIndex].question_id];
          const isSelected = selectedOptionId === option.option_id;

          return (
            <button
              key={option.option_id}
              className={`quiz-option ${isSelected ? (isAnswerCorrect ? 'correct' : 'incorrect') : ''}`}
              disabled={selectedOptionId !== undefined}
              onClick={() => handleOptionSelect(questions[currentQuestionIndex].question_id, option.option_id)}
            >
              <span className="option-label">{label})</span>
              <span>{option.option_text}</span>
            </button>
          );
        })}
      </div>

      {/* Image Overlay */}
      {isOverlayOpen && (
        <div className="q-image-overlay" onClick={closeOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <img src={overlayImage} alt="Overlay" className="overlay-image" />
            <button className="close-overlay" onClick={closeOverlay}>X</button>
          </div>
        </div>
      )}

      {showExitModal && (
        <div className="exit-modal">
          <div className="exit-modal-content">
            <h2>Are you sure you want to Exit?</h2>
            <div className="exit-modal-buttons">
              <button onClick={cancelExitQuiz}>No, Let's Continue</button>
              <button onClick={confirmExitQuiz}>Yes, Exit Now</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizPage;
